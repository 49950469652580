<template>
  <section>
    <h1>{{ $t('security.forgottenPassword.title') }}</h1>
    <p>{{ $t('security.resetPassword.message') }}</p>
    <v-form>
      <v-text-field v-model="username"
                    :label="$t('security.resetPassword.inputs.username')"
                    outlined
      />
      <v-btn type="submit"
             color="primary"
             @click.prevent="onSubmitForm"
      >{{ $t('security.resetPassword.button') }}
      </v-btn>
    </v-form>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RecoveryPassword',

  data: () => ({
    username: '',
  }),

  methods: {
    ...mapActions(['recoveryPassword']),
    onSubmitForm () {
      this.recoveryPassword({ username: this.username })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/backgroundPage";
</style>
